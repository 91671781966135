<template>
  <div>
    <div class="standard-page privacy-policy px-4 py-3 px-md-6 py-md-8">
      <!-- show logo and login CTA -->
      <div class="d-flex justify-space-between align-center align-md-start">
        <v-img
          contain
          width="70"
          src="/img/logo.png"
          class="flex-grow-0 cursor-pointer"
          @click="$router.push({ name: 'Home' })"
        />

        <v-spacer />

        <v-btn
          depressed
          outlined
          small
          class="font-weight-bold"
          :to="{ name: 'Login' }"
        >
          {{ isAuthenticated ? "Dashboard" : "Login" }}
        </v-btn>
      </div>

      <h1 class="my-4 my-sm-6 my-md-8 my-lg-12 text-h3 text-lg-h1">
        Articles
      </h1>

      <router-link
        v-for="item in links"
        :key="item.text"
        :to="item.to"

        class="d-block accent mb-4"
      >
        {{ item.text }}
      </router-link>
    </div>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of this component
  name: "ArticlesIndex",

  // Local data variables
  data: () => ({
    // Key map of the routes and their titles
    links: [
      {
        text: `${window.host.name} is MORE accurate at gender and age recognition than Microsoft & Amazon!`,
        to: {
          name: "Articles/View",
          params: {
            slug: "gender-and-age"
          }
        }
      },

      {
        text: "How to determine Instagram account geo location with 97% accuracy.",
        to: {
          name: "Articles/View",
          params: {
            slug: "geo-location"
          }
        }
      },

      {
        text: "Minimising sampling error by discovering posts early.",
        to: {
          name: "Articles/View",
          params: {
            slug: "minimising-sampling-error"
          }
        }
      }
    ]
  }),

  // Computed data variables
  computed: {
    /**
     * Whether or not the user is logged in
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    }
  }
}
</script>
